import React from "react"
import { Button, Media, Row, Col } from 'react-bootstrap'
import { Link, navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from 'react-bootstrap/Container'
import CommonFlex from '../components/commonFlex'
import Footer from '../components/footer'
import { bdstatistics, apiURL, mqStatistics, GetQueryString } from '../components/census'
// import Vanta from '../components/Vanta'
import Consult from '../components/consult'

import '../components/animate-index.css'

import './../assets/css/engine.css'
import './../components/media.css'
import './../assets/css/index.css'
import './../components/release.css'
import './../assets/css/aritclesDetail.css'
import './../assets/css/market-cases.css'
import './../assets/css/bootstrap.min.css'

// `<div>
// <h5 class="product-title">报警管理</h5>
// <div class="product-secord-box">
//   <div class="product-desc product-font"><span class="product-peical">定制</span>精准报警逻辑定制，支持自定义复杂报警规则</div>
// </div> 
// <div class="product-secord-box">
//   <div class="product-desc product-font"><span class="product-peical">分析</span>报警数据精准分析，多维度统计报警数据</div>
// </div> 
// <div class="product-secord-box">  
//   <div class="product-desc product-font"><span class="product-peical">推送</span>报警信息多维推送，支持声音、短信、电话、微信推送</div>
// </div>
// <div class="product-secord-box">  
//   <div class="product-desc product-font"><span class="product-peical">联动</span>设备全自动联动，支持视频录像、云台控制、指令下发、脚本控制</div>
// </div>
// <div class="product-secord-box">  
//   <div class="product-desc product-font"><span class="product-peical">预警</span>AI设备状态预警，具备机器学习、人工智能分析及预警的能力</div>
// </div>
// </div>`
let url = require('./../images/可视化33.png')
let engineObj = {
    '数据采集与控制引擎': {
        bannerData: {
            title: '数据采集与控制引擎',
            desc: '海量驱动库、分布式集群、高稳定性。实现快速的设备接入、报警、自动化控制及远程控制，具备完整的SCADA功能。',
            banner: require('./../images/单帧10002.png'),
            key: 'engine-img-2',
            height: 445
        },
        contentData: [
          {
            title: '驱动类型丰富',
            img: require('./../images/数据采集1.png'),
            tag_data: `<div>
              <h5 class="product-title">驱动类型丰富</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">覆盖包括通用驱动、厂商驱动、行业驱动、定制驱动、网关驱动、云平台驱动等90%以上的驱动类型。 </div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '分布式集群',
            img: require('./../images/数据采集2.png'),
            tag_data: `<div>
              <h5 class="product-title">分布式集群</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">分布式集群实现高可用性和可拓展性。</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '稳定性强',
            img: require('./../images/数据采集3.png'),
            tag_data: `<div>
              <h5 class="product-title">稳定性强</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">断网续传、主备驱动、链接管理。</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '数据接口，第三方系统快速集成能力',
            img: require('./../images/数据采集4.png'),
            tag_data: `<div>
              <h5 class="product-title">数据接口，第三方系统快速集成能力</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">生产平台提供数据库</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">设备平台提供API接口</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '报警能力',
            img: require('./../images/数据采集5.png'),
            tag_data: `<div>
              <h5 class="product-title">报警能力</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">精准的报警逻辑</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">报警数据精准分析</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '数据管理',
            img: require('./../images/数据采集6.png'),
            tag_data: `<div>
              <h5 class="product-title">数据管理</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">表功能丰富，设备表、部门表及常规表灵活定义</div>
              </div> 
            </div>`,
            name: 'model'
          },
        ]
    },
    '可视化组态引擎': {
        bannerData: {
            title: '三维可视化组态引擎',
            desc: '一套可视化工具满足多场景。丰富的可视化看板及组件，满足各类工艺流程图、数据可视化需求。支持三维编辑、图形绘制、图表设计。',
            banner: require('./../images/单帧10003.jpg'),
            key: 'engine-img-2',
            height: 445
        },
        contentData: [
            {
                title: '一套可视化工具满足多场景',
                img: require('./../images/可视化1.png'),
                tag_data: `<div>
                  <h5 class="product-title">一套可视化工具满足多场景</h5>
                  <div class="product-secord-box">
                    <div class="product-desc product-font">沉淀行业丰富资产，预置大量模板，可快速在多个项目间复用和灵活扩展，快速实现批量复制。</div>
                  </div> 
                </div>`,
                name: 'model'
            },
            {
              title: '工艺流程图',
              img: require('./../images/可视化2.png'),
              tag_data: `<div>
                <h5 class="product-title">工艺流程图</h5>
                <div class="product-secord-box">
                  <div class="product-desc product-font">秒级实时显示千级数据点</div>
                </div> 
                <div class="product-secord-box">
                  <div class="product-desc product-font">几乎所有元素都可数据绑定</div>
                </div> 
              </div>`,
              name: 'model'
          },
          {
            title: 'GIS，物联展现的重要形式',
            img: require('./../images/可视化3.png'),
            tag_data: `<div>
              <h5 class="product-title">GIS，物联展现的重要形式</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">实时位置，实时报警</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">点、线、面资产显示</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">拆分、合并、聚合、钻取</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '视频，强悍的GO开源流媒体框架',
            img: require('./../images/可视化4.png'),
            tag_data: `<div>
              <h5 class="product-title">视频，强悍的GO开源流媒体框架</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font"> 高性能、并发处理、更简洁更流畅</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '支持多种摄像头及流媒体格式',
            img: require('./../images/可视化5.png'),
            tag_data: `<div>
              <h5 class="product-title">支持多种摄像头及流媒体格式</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">支持云台控制，历史回放</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">支持GB28181、GB37300、JT1078、海康ISUP、ISC等多种平台级协议。</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '三维展示能力',
            img: require('./../images/可视化6.png'),
            tag_data: `<div>
              <h5 class="product-title">三维展示能力</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">支持常见的三维模型导入</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">绑定三维空间中的物理参数</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">对接主流数字孪生平台</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '自定义组件能力',
            img: require('./../images/可视化7.png'),
            tag_data: `<div>
              <h5 class="product-title">自定义组件能力</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">无限扩展你的组件仓库</div>
              </div> 
            </div>`,
            name: 'model'
          }
        ]
    },
    '数据分析引擎': {
        bannerData: {
            title: '数据分析引擎',
            desc: '灵活报表曲线，满足各类型数据分类及分析需求。毫秒级数据反馈速度，快速响应客户分析条件变换查询需求。通过机器学习、融合各种计算模型、人工智能算法实现各种AI大数据分析。',
            banner: require('./../images/单帧10004.png'),
            key: 'engine-img-2',
            height: 593,
        },
        contentData: [
          {
            title: '小报表也有大学问',
            img: require('./../images/数据分析1.png'),
            tag_data: `<div>
              <h5 class="product-title">小报表也有大学问</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">平台高级报表功能，提供类似Excel的报表工具，并且支持高级打印功能，满足用户对于数据分析和报表呈现的多元化需求。
                </div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '强悍的物联数据分析工具',
            img: require('./../images/数据分析11.png'),
            tag_data: `<div>
              <h5 class="product-title">强悍的物联数据分析工具</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">平台可以将历史采集的数据进行实时分析，进行聚合、同比、环比展示，用生产中的数据分析对比系统的标准线，数据产生偏差系统则会实时产生报警。
              </div>
            </div>`,
            name: 'model'
          },
          {
            title: '基于平台数据的BI工具',
            img: require('./../images/数据分析22.png'),
            tag_data: `<div>
              <h5 class="product-title">基于平台数据的BI工具</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">强大BI能力，结合关系型数据和物联数据，创建新的数据集做深度分析，用数据视图的方式，从不同维度分析洞察数据。</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '基于平台数据的BI工具',
            img: require('./../images/数据分析2.png'),
            tag_data: `<div>
              <div class="product-secord-box">
                <div class="product-desc product-font">数据分析数据源可与可视化画面直接打通，在可视化中做数据报表更加简单。</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: 'AI算法',
            img: require('./../images/数据分析3.png'),
            tag_data: `<div>
              <h5 class="product-title">AI算法</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">结合机器学习框架，基于数据做AI分析，实现设备诊断、预测、数据训练、设备模型与平台报警事件的联动。</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: 'AI算法模型库',
            img: require('./../images/数据分析4.png'),
            tag_data: `<div>
              <h5 class="product-title">AI算法模型库</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">平台集成了丰富的专业领域的算法模型，让AI赋能行业应用。</div>
              </div> 
            </div>`,
            name: 'model'
          }
        ]
    },
    '业务流引擎': {
        bannerData: {
            title: '业务流引擎',
            desc: '自研核心引擎，满足物联数据需求。可集群部署的流程引擎，满足高负载的事件触发。',
            banner: require('./../images/单帧10005.png'),
            key: 'engine-img-2',
            height: 445,
            secord_title: '流程引擎轻松搞定业务流转',
            moreModule: true
        },
        contentData: [
          {
            title: '流程引擎轻松搞定业务流转',
            img: require('./../images/业务流1.png'),
            tag_data: `<div>
              <h5 class="product-title">报警流程、设备联动</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">可实现报警数据与流程结合，根据业务场景进行流程节点的配置，触发数据可自定义报警范围及报警动作</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">可实现业务数据与流程结合，通过流程依据业务需求对业务数据进行处理</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">可实现业务场景与人工的结合，包括但不限于对于流程进行审批、填写。</div>
              </div> 
            </div>`,
            name: 'model'
          },
          {
            title: '业务联动、数据处理',
            img: require('./../images/业务流2.png'),
            tag_data: `<div>
              <h5 class="product-title">业务联动、数据处理</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">人工任务按状态统一管理</div>
              </div>
              <div class="product-secord-box">
                <div class="product-desc product-font">审批及填写一键处理，多种审批形式自由定义</div>
              </div>
              <div class="product-secord-box">
                <div class="product-desc product-font">任务生成同步站内消息通知，增强工作流的及时性</div>
              </div>
              <div class="product-secord-box">
                <div class="product-desc product-font">可实现微信公众号工单通知</div>
              </div>
            </div>`,
            name: 'model'
          },
          {
            title: '定时任务、审批工作流',
            img: require('./../images/业务流3.png'),
            tag_data: `<div>
              <h5 class="product-title">定时任务、审批工作流</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">定时任务功能允许用户设置特定的时间触发任务的执行。</div>
              </div>
              <div class="product-secord-box">
                <div class="product-desc product-font">引擎会按照预定的时间表自动触发任务，确保任务按时执行。</div>
              </div>
              <div class="product-secord-box">
                <div class="product-desc product-font">审批工作流功能可定义审批流程的各个环节、参与人员和条件，将任务在不同的审批节点间流转。</div>
              </div>
            </div>`,
            name: 'model'
          },
        ]
    },
    '二次开发引擎': {
        bannerData: {
            title: '二次开发引擎',
            desc: '可扩展是一个平台最基本的能力。',
            banner: require('./../images/单帧10006.png'),
            key: 'engine-img-5',
            height: 547,
            // secord_title: 'AIRIOT所有引擎功能均可自由扩展开发'
        },
        contentData: [
          {
            title: 'AIRIOT所有引擎功能均可自由扩展开发',
            img: require('./../images/二次开发1.png'),
            tag_data: `<div>
              <h5 class="product-title">AIRIOT所有引擎功能均可自由扩展开发</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">驱动、画面组件、前端页面和组件</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">流程节点、算法服务均可扩展</div>
              </div> 
              <div class="product-secord-box">  
                <div class="product-desc product-font">支持主流语言开发：Java、C#、GO、Node、Python等</div>
              </div>
            </div>`,
            name: 'model'
          },
          {
            title: '丰富的扩展开发套件（SDK）',
            img: require('./../images/二次开发2.png'),
            tag_data: `<div>
              <h5 class="product-title">丰富的扩展开发套件（SDK）</h5>
              <div class="product-secord-box">
                <div class="product-desc product-font">前端底层框架xadmin</div>
              </div> 
              <div class="product-secord-box">
                <div class="product-desc product-font">全API接口，完善的API接口文档</div>
              </div> 
              <div class="product-secord-box">  
                <div class="product-desc product-font">可以在线调试</div>
              </div>
            </div>`,
            name: 'model'
          }
        ]
    },
    'v4': {
      bannerData: {
        title: 'AIRIOT4.0版本全新上线',
        desc: '化繁为简 创新无限',
        banner: require('./../images/单帧10001.png'),
        key: 'engine-img-5',
        height: 547,
      },
      contentData: [
        {
          title: '数据采集与控制引擎',
          img: require('./../images/单帧10002.png'),
          tag_data: `<div>
            <h5 class="product-title">海量驱动</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">覆盖包括通用驱动、厂商驱动、行业驱动、定制驱动、网关驱动、云平台驱动等90%以上的驱动类型。分布式集群实现高可用性和可拓展性。</div>
            </div> 
            <div class="product-secord-box">
              <div class="product-desc product-font">数据接口</div>
            </div> 
            <div class="product-secord-box">  
              <div class="product-desc product-font">强大的第三方系统快速集成能力，无限释放更多可能。</div>
            </div>
            <h5 class="product-title">报警能力</h5>
            <div class="product-secord-box">  
              <div class="product-desc product-font">精准的报警逻辑</div>
              <div class="product-desc product-font">报警数据精准分析</div>
            </div>
          </div>`,
          name: 'model'
        },
        {
          title: '可视化组态引擎',
          img: require('./../images/单帧10003.jpg'),
          tag_data: `<div>
            <h5 class="product-title">强大的工艺流程图</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">一个画面秒级实时显示千级数据点</div>
            </div> 
            <h5 class="product-title">GIS地图</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">实时位置，实时报警，点、线、⾯资产显示</div>
            </div> 
            <h5 class="product-title">视频，强悍的GO开源流媒体框架</h5>
            <div class="product-secord-box">  
              <div class="product-desc product-font">⽀持多种摄像头及流媒体格式</div>
            </div>
            <h5 class="product-title">三维展示能力</h5>
            <div class="product-secord-box">  
              <div class="product-desc product-font">⽀持三维模型绑定三维空间中的物理参数</div>
              <div class="product-desc product-font">对接主流数字孪生平台</div>
            </div>
            <h5 class="product-title">⾃定义组件能⼒</h5>
            <div class="product-secord-box">  
              <div class="product-desc product-font">⽆限扩展你的组件仓库</div>
            </div>
          </div>`,
          name: 'model'
        },
        {
          title: '数据分析引擎',
          img: require('./../images/单帧10004.png'),
          tag_data: `<div>
            <h5 class="product-title">基于平台数据的BI工具</h5>
            <div class="product-secord-box">
              <div class="product-secord-title">可视化的数据关联编辑</div>
              <div class="product-desc product-font">数据深度分析功能</div>
              <div class="product-desc product-font">数据分析数据源可与可视化画面直接打通</div>
            </div> 
            <div class="product-secord-box">
              <div class="product-secord-title">AI算法</div>
              <div class="product-desc product-font">结合机器学习框架</div>
              <div class="product-desc product-font">AI算法模型库</div>
            </div> 
          </div>`,
          name: 'model'
        },
        {
          title: '业务流引擎',
          img: require('./../images/单帧10005.png'),
          tag_data: `<div>
            <h5 class="product-title">自研核心引擎，满足物联数据需求</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">可集群部署的流程引擎</div>
            </div> 
            <div class="product-secord-box">
              <div class="product-desc product-font">满足高负载的事件触发</div>
            </div> 
            <h5 class="product-title">流程引擎轻松搞定业务流转</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">报警流程、设备联动</div>
            </div> 
            <div class="product-secord-box">
              <div class="product-desc product-font">业务联动、数据处理</div>
            </div> 
            <div class="product-secord-box">
              <div class="product-desc product-font">定时任务、审批工作流</div>
            </div> 
          </div>`,
          name: 'model'
        },
        {
          title: '二次开发引擎',
          img: require('./../images/单帧10006.png'),
          tag_data: `<div>
            <h5 class="product-title">可扩展是一个平台最基本的能力</h5>
            <div class="product-secord-box">
              <div class="product-desc product-font">AIRIOT所有引擎功能均可自由扩展开发</div>
            </div> 
            <div class="product-secord-box">
              <div class="product-desc product-font">支持主流语言开发：Java、C#、GO、Node、Python等</div>
            </div> 
          </div>`,
          name: 'model'
        },
      ]
    }
}

const ProductPage = () => {
  let [ hash, setHash ] = React.useState(null)

  React.useEffect(() => {
    bdstatistics()
    mqStatistics()
  }, [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      let windowHeight = document.documentElement.clientHeight

      window.addEventListener('scroll', (event) => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let animate_box = document.getElementsByClassName('product-media');

        let animate_box_left = document.getElementsByClassName('product-left-animate');
        let animate_box_right = document.getElementsByClassName('product-right-animate');

        let box = document.getElementsByClassName('img-bg-edit')
        let animate_box_media = document.getElementsByClassName('product-media')

      //   for(let i=0; i<animate_box.length;i++) {
      //     if(animate_box[i].offsetTop < (windowHeight + scrollTop + 120)) {
      //       if(i%2 === 0) {
      //         box[i].style.animation = `slide-bck-center 4s ease-in-out 0s 1  forwards`
      //       }else {
      //         box[i].style.animation = `slide-bck-center1 4s ease-in-out 0s 1  forwards`
      //       }
      //     }else {
      //       if(i%2 === 0) {
      //         box[i].style.animation = `slide-bck-center2 4s ease-in-out 0s 1  forwards`
      //       }else {
      //         box[i].style.animation = `slide-bck-center3 4s ease-in-out 0s 1  forwards`
      //       }
      //     }
      //   }
 
        for(let i=0; i<animate_box.length;i++) {
          if(animate_box[i].offsetTop < (windowHeight + scrollTop)) {
            animate_box_left[i].style.animation = `fadeInLeft 500ms linear`
            animate_box_right[i].style.animation = `fadeInRight 500ms linear`
          } else {
            animate_box_left[i].style.animation = `fadeInRight 500ms linear`
            animate_box_right[i].style.animation = `fadeInLeft 500ms linear`
          }
        }

      })
      
      if(window.location.hash && window.location.hash.indexOf('#') >-1)  {
        setHash(window.location.hash.substr(window.location.hash.indexOf('#')+1))
      }
    }
  },  [])

  React.useEffect(() => {
    if(typeof window !== "undefined") {
      // 跳转到指定位置
      let product_media = document.getElementsByClassName('product-media');
      if(hash) {
        for(let i=0; i<product_media.length;i++) {
          let name = product_media[i].getAttribute('name')
          if(name == hash) {
            window.scrollTo({
              top: product_media[i].offsetTop -150
            })
          }
        }
      }
    }
  }, [hash])

  const getCookie = (name) => {
    var strcookie = document.cookie;//获取cookie字符串
    var arrcookie = strcookie.split("; ");//分割
    //遍历匹配
    for (var i = 0; i < arrcookie.length; i++) {
      var arr = arrcookie[i].split("=");
      if (arr[0] == name) {
        return arr[1];
      }
    }
    return "";
  }

  const downPlat = () => {
    let cookieData = getCookie('data')
    if (cookieData && JSON.parse(cookieData)) {
      let contact_name = JSON.parse(cookieData).contact_name
      navigate("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve")
    } else {
      navigate("https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve")
    }
  }

  const toHerf = () => {
    if(typeof window !== "undefined") {
      setHash('model')
      window.scrollTo({
        top: 1000
      })
    }
  }

  let name = GetQueryString('name') || '数据采集与控制引擎'

  let string = "home-bg home-index engine-home" 
  // if(engineObj[name].bannerData.title == '数据分析引擎') {
  //   string = "home-bg home-index engine-home engine-home-bg"
  // } else if(name == 'v4') {
  //   string = "home-bg home-index engine-home engine-home-bg engine-home-4"
  // } 
  // else if(engineObj[name].bannerData.title == 'AIRIOT4.0版本全新上线') {
  //   string = "home-bg home-index engine-home engine-home-bg engine-home-4"
  // }
  return (
    <div className="product-page engine-page">
      <Consult />
      {
        name == 'v4' ? <div className='home-bg home-index engine-home  engine-home-bg engine-home-4'>
        <Layout typePage="engine" banner={true} noContainer={false}> 
            <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
              <div className="engine-plat-common">
                <div className="plat-jumbotron-common">
                    <div className="jumbotron-left-common">
                        <div className="jumbotron-title-common">
                          <img src={require('./../images/airiot.png')} className="engine-img-left"/>
                          4.0版本
                          <span className="engine-jumbotron-span">重磅发布</span>
                        </div>
                        <div className="jumbotron-desc-common">{engineObj[name].bannerData.desc}</div>
                        <Button variant="primary" className="engine-button-product"><a href="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve">获取产品体验</a></Button>
                    </div>
                </div>
                <div className="jumbotron-right-common" >
                    <img className={`jumbotron-img ${engineObj[name].bannerData.key}`} src={engineObj[name].bannerData.banner}></img>
                </div>
              </div>
        </Layout>
    </div>
     :
      <div className={engineObj[name].bannerData.title == '数据分析引擎' ? "home-bg home-index engine-home engine-home-bg" : "home-bg home-index engine-home" }>
        <Layout>
            <SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" />
            {
              engineObj[name].bannerData.title == '数据分析引擎' ?   <div className="engine-plat">
                <div className="plat-jumbotron">
                    <div className="jumbotron-left">
                        <div className="jumbotron-title">{engineObj[name].bannerData.title}</div>
                        <div className="jumbotron-desc">{engineObj[name].bannerData.desc}</div>
                        <Link to="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve" target="_blank">
                          <Button variant="primary" className="engine-button">申请试用</Button>
                        </Link>
                    </div>
                </div>
                {/* style={{ width: engineObj[name].bannerData.width }} */}
                <div className="jumbotron-right-common">
                    <img className={`jumbotron-img ${engineObj[name].bannerData.key}`} src={engineObj[name].bannerData.banner}></img>
                </div>
              </div> : engineObj[name].bannerData.title == 'AIRIOT4.0版本全新上线' ? <div className="engine-plat-common">
                <div className="plat-jumbotron-common">
                    <div className="jumbotron-left-common">
                        <div className="jumbotron-title-common">
                          <img src={require('./../images/airiot.png')} className="engine-img-left"/>
                          4.0版本
                          <span className="engine-jumbotron-span">重磅发布</span>
                        </div>
                        <div className="jumbotron-desc-common">{engineObj[name].bannerData.desc}</div>
                        <Button variant="primary" className="engine-button-product"><a href="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve" target="_blank">获取产品体验</a></Button>
                    </div>
                </div>
                <div className="jumbotron-right-common" >
                    <img className={`jumbotron-img ${engineObj[name].bannerData.key}`} src={engineObj[name].bannerData.banner}></img>
                </div>
              </div> : <div className="engine-plat-common">
                <div className="plat-jumbotron-common">
                    <div className="jumbotron-left-common">
                      <div>
                        <img />
                        <div className="jumbotron-title-common">{engineObj[name].bannerData.title}</div>
                      </div>
                      <div className="jumbotron-desc-common">{engineObj[name].bannerData.desc}</div>
                      <Link to="https://r8ja3mlg7i.feishu.cn/share/base/form/shrcn6Ns5Vk4fgiEanpunuN71Ve" target="_blank">
                        <Button variant="primary" className="engine-button">申请试用</Button>
                      </Link>
                    </div>
                </div>
                <div className="jumbotron-right-common" >
                    <img className={`jumbotron-img ${engineObj[name].bannerData.key}`} src={engineObj[name].bannerData.banner}></img>
                </div>
              </div>
            }
        </Layout>
        </div>

    }

      {
        engineObj[name].bannerData.title == 'AIRIOT4.0版本全新上线' ? <><div className="container content-box product-container">
            <CommonFlex title={engineObj[name].bannerData.title} desc=""></CommonFlex>
            </div>
            <>
              {
                engineObj[name].contentData.map((item, index) => {
                  if(index%2 != 0) {
                    return (
                      <div style={{ background: '#eff0ff' }}>
                      <div className="container content-box product-container">
                      <div>
                        {
                          item.bg_data ? <div style={{ position: 'relative', zIndex: 8 }} dangerouslySetInnerHTML={{__html: item.bg_data }}></div> : null
                        }
                        <Media className="product-media row" name={item.name} style={ item.bg_data ? { position: 'relative', zIndex: 99, padding: '2rem' } : { padding: '2rem'} }>
                          <Col sm={6} style={{ margin: 'auto 0', zIndex: 9 }} className="product-left-animate">
                            <div className={`box-animation box-${index}`} style={{ width: '96%', height: '100%'}}>
                                <img className="img-bg-edit"  src={item.img} style={{ width: '100%', height: '100%', marginBottom: '-2rem' }} alt="Generic placeholder" />
                            </div>
                          </Col>
                          <Col sm={6}  className="product-sm-animate product-right-animate" style={{ padding: '0 0 0 54px',  zIndex: 9, margin: 'auto 0' }}>
                            <Media.Body>
                              <div className="product-pos">
                                <div className="product-pos" dangerouslySetInnerHTML={{__html: item.tag_data }}></div>
                              </div>
                            </Media.Body>
                          </Col>
                        </Media>
                      </div>
                      </div>
                      </div>
                    )
                  }else {
                    return (
                      <div className="container content-box product-container">
                      <div> 
                        {
                          item.bg_data ? <div style={{ position: 'relative', zIndex: 8 }} dangerouslySetInnerHTML={{__html: item.bg_data }}></div> : null
                        }           
                        <Media className="product-media row" name={item.name} style={ item.bg_data ? { position: 'relative', zIndex: 99} : null }>
                          <Col sm={6} className="product-sm-animate product-left-animate" style={{ zIndex: 9, margin: 'auto 0' }}>
                            <Media.Body>
                              <div className="product-pos">
                                <div className="product-pos" dangerouslySetInnerHTML={{__html: item.tag_data }}></div>
                              </div>
                            </Media.Body>
                          </Col>
                          <Col sm={6} style={{ margin: 'auto 0', zIndex: 9 }} className="product-right-animate">
                            <div className={`box-animation box-${index}`} style={{ width: '100%', height: '90%' }}>
                                <img className="img-bg-edit"  src={item.img} style={{ width: '100%', height: '100%', marginBottom: '-2rem' }} alt="Generic placeholder" />
                            </div>
                          </Col>
                        </Media>
                      </div>
                      </div>
                    )
                  }
                })
              }
            </>
          </>
          :   
          <div className="container content-box product-container">
            <CommonFlex title={ engineObj[name]?.bannerData?.secord_title || engineObj[name].bannerData.title} desc=""></CommonFlex>
            <>
              {
                engineObj[name].contentData.map((item, index) => {
                  if(index%2 != 0) {
                    return (
                      <div>
                        {
                          item.bg_data ? <div style={{ position: 'relative', zIndex: 8 }} dangerouslySetInnerHTML={{__html: item.bg_data }}></div> : null
                        }
                        <Media className="product-media row" name={item.name} style={ item.bg_data ? { position: 'relative', zIndex: 99} : null }>
                          <Col sm={6} style={{ margin: 'auto 0', zIndex: 9 }} className="product-left-animate">
                            <div className={`box-animation box-${index}`} style={{ width: '96%', height: '100%'}}>
                                <img className="img-bg-edit"  src={item.img} style={{ width: '100%', height: '100%', marginBottom: '-2rem' }} alt="Generic placeholder" />
                            </div>
                          </Col>
                          <Col sm={6}  className="product-sm-animate product-right-animate" style={{ padding: '0 0 0 54px',  zIndex: 9, margin: 'auto 0' }}>
                            <Media.Body>
                              <div className="product-pos">
                                <div className="product-pos" dangerouslySetInnerHTML={{__html: item.tag_data }}></div>
                              </div>
                            </Media.Body>
                          </Col>
                        </Media>
                      </div>
                    )
                  }else {
                    return (
                      <div> 
                        {
                          item.bg_data ? <div style={{ position: 'relative', zIndex: 8 }} dangerouslySetInnerHTML={{__html: item.bg_data }}></div> : null
                        }           
                        <Media className="product-media row" name={item.name} style={ item.bg_data ? { position: 'relative', zIndex: 99} : null }>
                          <Col sm={6} className="product-sm-animate product-left-animate" style={{ zIndex: 9, margin: 'auto 0' }}>
                            <Media.Body>
                              <div className="product-pos">
                                <div className="product-pos" dangerouslySetInnerHTML={{__html: item.tag_data }}></div>
                              </div>
                            </Media.Body>
                          </Col>
                          <Col sm={6} style={{ margin: 'auto 0', zIndex: 9 }} className="product-right-animate">
                            <div className={`box-animation box-${index}`} style={{ width: '100%', height: '90%' }}>
                                <img className="img-bg-edit"  src={item.img} style={{ width: '100%', height: '100%', marginBottom: '-2rem' }} alt="Generic placeholder" />
                            </div>
                          </Col>
                        </Media>
                      </div>
                    )
                  }
                })
              }

              {
                engineObj[name]?.bannerData?.moreModule ? <div style={{ marginBottom: '7.5rem' }}><div className="product-title" style={{ textAlign: 'center', margin: '2rem 0 3rem' }}>更多流程</div>
                <div className="box-animation box-2">
                  <img className="img-bg-edit" style={{ width: '100%', height: '100%' }} src={require('./../images/可视化more.png')}/>
                </div></div> : null
              }
            </>
          </div>
      }
      <Footer></Footer>
    </div>
  )
}

export default ProductPage

